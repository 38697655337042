var render = function () {
  var _vm$product, _vm$product2, _vm$product2$iconDark, _vm$product3, _vm$product3$iconLigh;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.queries.product.loading ? _c('TalpaLoaderWrapper', {
    attrs: {
      "transparent": true
    }
  }) : _vm.error ? _c('ErrorAtom', {
    attrs: {
      "error": _vm.error
    }
  }) : _vm.product ? _c('ProductIconsMolecule', {
    attrs: {
      "hasDarkIconOnly": (_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.hasDarkIconOnly,
      "iconDarkImageId": (_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : (_vm$product2$iconDark = _vm$product2.iconDark) === null || _vm$product2$iconDark === void 0 ? void 0 : _vm$product2$iconDark.id,
      "iconLightImageId": (_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : (_vm$product3$iconLigh = _vm$product3.iconLight) === null || _vm$product3$iconLigh === void 0 ? void 0 : _vm$product3$iconLigh.id
    },
    on: {
      "update:hasDarkIconOnly": function updateHasDarkIconOnly($event) {
        return _vm.handleUpdateProduct('hasDarkIconOnly', $event);
      },
      "update:iconDarkImageId": function updateIconDarkImageId($event) {
        return _vm.handleUpdateProduct('iconDarkImageId', $event);
      },
      "update:iconLightImageId": function updateIconLightImageId($event) {
        return _vm.handleUpdateProduct('iconLightImageId', $event);
      }
    }
  }) : _c('div', [_vm._v("Product '" + _vm._s(_vm.productId) + "' not found.")]);
}
var staticRenderFns = []

export { render, staticRenderFns }