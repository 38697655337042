var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TranslationEditorMolecule', {
    attrs: {
      "title": _vm.title,
      "availableLanguages": _vm.availableLanguages,
      "activeLanguageId": _vm.activeLanguageId,
      "hasSave": true,
      "hasSaveAll": true,
      "canSaveAll": _vm.canSaveAll,
      "hasRestore": true,
      "hasRestoreAll": true,
      "canRestoreAll": _vm.canRestoreAll,
      "markdownEnabled": true,
      "translatedTexts": _vm.localTranslatedTexts
    },
    on: {
      "set-active-language-id": function setActiveLanguageId($event) {
        return _vm.$emit('set-active-language-id', $event);
      },
      "set-translated-text": function setTranslatedText($event) {
        return _vm.setTranslatedText($event);
      },
      "save-translated-text": function saveTranslatedText($event) {
        return _vm.saveTranslatedText($event);
      },
      "restore-translated-text": function restoreTranslatedText($event) {
        return _vm.restoreTranslatedText($event);
      },
      "save-all-translated-texts": function saveAllTranslatedTexts($event) {
        return _vm.saveAllTranslatedTexts();
      },
      "restore-all-translated-texts": function restoreAllTranslatedTexts($event) {
        return _vm.restoreAllTranslatedTexts();
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }