var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ApolloQuery', {
    attrs: {
      "query": _vm.PRODUCT_QUERY,
      "variables": _vm.variables
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _ref$result = _ref.result,
          loading = _ref$result.loading,
          error = _ref$result.error,
          data = _ref$result.data;
        return [loading ? _c('TalpaLoaderWrapper', {
          attrs: {
            "transparent": true
          }
        }) : error ? _c('ErrorAtom', {
          attrs: {
            "error": error
          }
        }) : data ? [_c('ProductDetailsMolecule', {
          attrs: {
            "product": data.product
          }
        })] : _vm._e()];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }