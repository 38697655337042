var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ProductOverviewTemplate', [_c('ProductDetailsOrganism', {
    attrs: {
      "productId": _vm.productId
    }
  }), _c('ProductIconManagerOrganism', {
    attrs: {
      "productId": _vm.productId
    }
  }), _c('EditProductTranslationsOrganism', {
    attrs: {
      "productId": _vm.productId,
      "activeLanguageId": _vm.activeLanguageId,
      "title": 'ShortDescription',
      "translatedFieldName": 'shortDescription'
    },
    on: {
      "set-active-language-id": function setActiveLanguageId($event) {
        return _vm.$emit('set-active-language-id', $event);
      }
    }
  }), _c('EditProductTranslationsOrganism', {
    attrs: {
      "productId": _vm.productId,
      "activeLanguageId": _vm.activeLanguageId,
      "title": 'Description',
      "translatedFieldName": 'description'
    },
    on: {
      "set-active-language-id": function setActiveLanguageId($event) {
        return _vm.$emit('set-active-language-id', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }