<template>
  <ProductOverviewTemplate>
    <ProductDetailsOrganism :productId="productId" />
    <ProductIconManagerOrganism :productId="productId" />
    <EditProductTranslationsOrganism
      :productId="productId"
      :activeLanguageId="activeLanguageId"
      :title="'ShortDescription'"
      :translatedFieldName="'shortDescription'"
      @set-active-language-id="$emit('set-active-language-id', $event)"
    />
    <EditProductTranslationsOrganism
      :productId="productId"
      :activeLanguageId="activeLanguageId"
      :title="'Description'"
      :translatedFieldName="'description'"
      @set-active-language-id="$emit('set-active-language-id', $event)"
    />
  </ProductOverviewTemplate>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import ProductDetailsOrganism from '../Organisms/ProductDetailsOrganism'
import ProductIconManagerOrganism from '../Organisms/ProductIconManagerOrganism'
import EditProductTranslationsOrganism from '../Organisms/EditProductTranslationsOrganism'

const ProductOverviewTemplate = styled('div')`
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;
`

export default {
  props: {
    productId: {
      type: String,
      required: true,
    },
    activeLanguageId: {
      type: String,
      required: true,
    },
  },
  components: {
    ProductOverviewTemplate,
    ProductDetailsOrganism,
    ProductIconManagerOrganism,
    EditProductTranslationsOrganism,
  },
}
</script>
