<template>
  <TranslationEditorMolecule
    :title="title"
    :availableLanguages="availableLanguages"
    :activeLanguageId="activeLanguageId"
    :hasSave="true"
    :hasSaveAll="true"
    :canSaveAll="canSaveAll"
    :hasRestore="true"
    :hasRestoreAll="true"
    :canRestoreAll="canRestoreAll"
    :markdownEnabled="true"
    :translatedTexts="localTranslatedTexts"
    @set-active-language-id="$emit('set-active-language-id', $event)"
    @set-translated-text="setTranslatedText($event)"
    @save-translated-text="saveTranslatedText($event)"
    @restore-translated-text="restoreTranslatedText($event)"
    @save-all-translated-texts="saveAllTranslatedTexts()"
    @restore-all-translated-texts="restoreAllTranslatedTexts()"
  />
</template>

<script>
import TranslationEditorMolecule from '../Molecules/TranslationEditorMolecule'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import PRODUCT_QUERY from '#/graphql/marketplace/productQuery.gql'
import AVAILABLE_LANGUAGE_ENUMS from '#/graphql/marketplace/availableLanguageEnumsQuery.gql'
import CREATE_ONE_TRANSLATED_TEXT_MUTATION from '#/graphql/marketplace/createOneTranslatedTextMutation.gql'
import UPDATE_ONE_TRANSLATED_TEXT_MUTATION from '#/graphql/marketplace/updateOneTranslatedTextMutation.gql'

export default {
  props: {
    productId: {
      type: String,
      required: true,
    },
    activeLanguageId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    translatedFieldName: {
      type: String,
      required: true,
    },
  },
  components: { TranslationEditorMolecule },
  data() {
    return {
      product: null,
      localTranslatedTexts: [],
      availableLanguages: [],
    }
  },
  computed: {
    canSaveAll() {
      return this.localTranslatedTexts.some(s => s.canSave)
    },
    canRestoreAll() {
      return this.localTranslatedTexts.some(s => s.isDirty)
    },
  },
  methods: {
    setTranslatedText($event) {
      const idx = this.localTranslatedTexts.findIndex(f => f.id === $event.id)
      if (idx === -1) {
        throw new Error(`did not find '${$event?.id}' in localTranslatedTexts`)
      }
      const productTranslation = this.product?.translatedTexts.find(f => f.id === $event.id)
      const isDirty = $event.text !== productTranslation?.text
      const isCompleted = $event.text?.trim()?.length > 0
      this.$set(this.localTranslatedTexts, idx, {
        ...this.localTranslatedTexts[idx],
        text: $event.text,
        isDirty,
        isCompleted,
        canSave: isDirty && isCompleted,
        canRestore: isDirty,
      })
    },
    async saveTranslatedText(id) {
      const translatedText = this.localTranslatedTexts.find(f => f.id === id)
      if (!translatedText) {
        throw new Error(`did not find '${id}' in localTranslatedTexts`)
      }
      const res = translatedText.isNew ? await this.createTranslatedText(translatedText) : await this.updateTranslatedText(translatedText)
      if (res.data) {
        this.$apollo.queries.product.refetch()
      }
    },
    async createTranslatedText(translatedText) {
      return await this.$apollo.mutate({
        mutation: CREATE_ONE_TRANSLATED_TEXT_MUTATION,
        variables: {
          data: {
            text: translatedText.text,
            language: translatedText.language,
            translatableId: this.productId,
            translatableType: 'Product',
            translatedField: this.translatedFieldName,
          },
        },
      })
    },
    async updateTranslatedText(translatedText) {
      return await this.$apollo.mutate({
        mutation: UPDATE_ONE_TRANSLATED_TEXT_MUTATION,
        variables: {
          where: {
            id: translatedText.id,
          },
          data: {
            text: {
              set: translatedText.text,
            },
          },
        },
      })
    },
    restoreTranslatedText(id) {
      const idx = this.localTranslatedTexts.findIndex(f => f.id === id)
      if (idx === -1) {
        throw new Error(`did not find '${id}' in localTranslatedTexts`)
      }
      const productTranslation = this.product?.translatedTexts.find(f => f.id === id)
      if (!productTranslation) {
        throw new Error(`did not find '${id}' in product.translatedTexts`)
      }
      this.setTranslatedText(productTranslation)
    },
    async saveAllTranslatedTexts() {
      const saveableIds = this.localTranslatedTexts.filter(f => f.canSave).map(m => m.id)
      const promises = saveableIds.map(id => this.saveTranslatedText(id))
      await Promise.all(promises)
      this.$apollo.queries.product.refetch()
    },
    restoreAllTranslatedTexts() {
      const dirtyIds = this.localTranslatedTexts.filter(f => f.isDirty).map(m => m.id)
      dirtyIds.forEach(id => {
        this.restoreTranslatedText(id)
      })
    },
  },
  apollo: {
    product: {
      query: PRODUCT_QUERY,
      variables() {
        return {
          where: {
            id: this.productId,
          },
        }
      },
      skip() {
        return this.availableLanguages?.length < 1
      },
      result({ data }) {
        if (data?.product) {
          const cloned = cloneDeep(data?.product.translatedTexts)
            .filter(f => f.translatedField === this.translatedFieldName)
            .map(m => ({
              ...m,
              isDirty: false,
              canSave: false,
              isCompleted: m.text?.trim()?.length > 0,
            }))
          this.localTranslatedTexts = this.availableLanguages.reduce((localTranslatedTexts, language) => {
            const translatedText = cloned.find(f => f.language === language)
            if (translatedText) {
              localTranslatedTexts.push({
                ...translatedText,
                isNew: false,
                isDirty: false,
                canSave: false,
                isCompleted: translatedText.text?.trim()?.length > 0,
              })
            } else {
              localTranslatedTexts.push({
                id: uuidv4(),
                text: '',
                language,
                isNew: true,
                isDirty: true,
                canSave: false,
                isCompleted: false,
              })
            }
            return localTranslatedTexts
          }, [])
        }
      },
    },
    availableLanguages: {
      query: AVAILABLE_LANGUAGE_ENUMS,
      update: data => {
        return (data?.availableLanguageEnums?.enumValues ?? []).map(m => m.name)
      },
    },
  },
}
</script>
