<template>
  <ProductOverviewMolecule>
    <EditableFieldMolecule
      v-for="field in fields"
      :key="field.id"
      :label="field.label"
      :value="field.value"
      :isReadonly="true"
      :isIdLike="field.isIdLike"
      :isDateTime="field.isDateTime"
      :hasCopyToClipboard="field.hasCopyToClipboard"
    />
  </ProductOverviewMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import EditableFieldMolecule from '@/components/Atomic/Molecules/EditableFieldMolecule'

const ProductOverviewMolecule = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 1rem;
`

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    ProductOverviewMolecule,
    EditableFieldMolecule,
  },
  data() {
    return {}
  },
  computed: {
    fields() {
      const fields = [
        {
          id: 'id',
          label: 'ID',
          isIdLike: true,
          hasCopyToClipboard: true,
        },
        {
          id: 'type',
          label: 'Type',
        },
        {
          id: 'name',
          label: 'Name',
        },
      ]
      if (this.product?.type === 'APP') {
        fields.push({
          id: 'entryPoint',
          label: 'Entry point',
        })
      }
      fields.push(
        {
          id: 'createdAt',
          label: 'Created at',
          isDateTime: true,
          hasCopyToClipboard: true,
        },
        {
          id: 'updatedAt',
          label: 'Updated at',
          isDateTime: true,
          hasCopyToClipboard: true,
        },
      )
      return fields.map(field => {
        const valueRaw = this.product[field.id]
        let value = valueRaw
        if (Array.isArray(value)) {
          value = value.join(', ')
        }
        return {
          ...field,
          valueRaw,
          value,
        }
      })
    },
  },
}
</script>
