<template>
  <TalpaLoaderWrapper v-if="$apollo.queries.product.loading" :transparent="true" />
  <ErrorAtom v-else-if="error" :error="error" />
  <ProductIconsMolecule
    v-else-if="product"
    :hasDarkIconOnly="product?.hasDarkIconOnly"
    :iconDarkImageId="product?.iconDark?.id"
    :iconLightImageId="product?.iconLight?.id"
    @update:hasDarkIconOnly="handleUpdateProduct('hasDarkIconOnly', $event)"
    @update:iconDarkImageId="handleUpdateProduct('iconDarkImageId', $event)"
    @update:iconLightImageId="handleUpdateProduct('iconLightImageId', $event)"
  />
  <div v-else>Product '{{ productId }}' not found.</div>
</template>

<script>
import { TalpaLoaderWrapper, ErrorAtom } from '@common/components'
import { FlashMessages } from '@common/singletons'

import ProductIconsMolecule from '../Molecules/ProductIconsMolecule'

import PRODUCT_QUERY from '#/graphql/marketplace/productQuery.gql'
import UPDATE_PRODUCT_MUTATION from '#/graphql/marketplace/updateOneIProductMutation.gql'
// import UPLOAD_MUTATION from '#/graphql/uploads/uploadMutation.gql'

export default {
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  components: {
    TalpaLoaderWrapper,
    ErrorAtom,
    ProductIconsMolecule,
  },
  data() {
    return {
      isUploading: false,
      error: null,
      product: null,
    }
  },
  computed: {
    hasDarkIconOnly: {
      get: function () {
        return this.product?.hasDarkIconOnly
      },
      set: function (hasDarkIconOnly) {
        this.$emit('update:product', {
          ...this.product,
          hasDarkIconOnly,
        })
      },
    },
  },
  methods: {
    async handleUpdateProduct(field, $event) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_PRODUCT_MUTATION,
          variables: {
            where: {
              id: this.productId,
            },
            data: {
              [field]: {
                set: $event,
              },
            },
          },
        })
        this.$apollo.queries.product.refetch()
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    // async upload(field, { target: { files = [] } }) {
    //   try {
    //     if (!files.length) {
    //       return
    //     }
    //     this.isUploading = true
    //     const res = await this.$apollo.mutate({
    //       mutation: UPLOAD_MUTATION,
    //       variables: {
    //         file: files[0],
    //       },
    //       context: {
    //         hasUpload: true,
    //       },
    //     })
    //     this.isUploading = false
    //     const upload = res?.data?.upload
    //     let variables = {
    //       where: {
    //         id: this.productId,
    //       },
    //     }
    //     if (field === 'iconLight') {
    //       variables.data = {
    //         iconLightImageId: {
    //           set: upload?.id,
    //         },
    //       }
    //     } else if (field === 'iconDark') {
    //       variables.data = {
    //         iconDarkImageId: {
    //           set: upload?.id,
    //         },
    //       }
    //     } else {
    //       throw new Error(`Unhandled upload field '${field}'`)
    //     }
    //     const updateProduct = await this.$apollo.mutate({
    //       mutation: UPDATE_PRODUCT_MUTATION,
    //       variables,
    //     })
    //     if (updateProduct) {
    //       this.$apollo.queries.product.refetch()
    //     }
    //   } catch (err) {
    //     this.error = err
    //   }
    // },
  },
  apollo: {
    product: {
      query: PRODUCT_QUERY,
      variables() {
        return {
          where: {
            id: this.productId,
          },
        }
      },
      manual: true,
      result({ data }) {
        this.error = null
        this.product = data?.product
      },
      error(err) {
        this.error = err
      },
    },
  },
}
</script>
